<template>
  <div class="contact">
    <div class="banner">
      <div class="title">Contact Us</div>
      <div class="text">
        We have sites in both Cambridge, US and Shanghai, China.
      </div>
    </div>
    <div class="content">
      <div class="map"><img width="100%" src="../assets/contact/map_whole.png" alt=""></div>
      <div class="cards">
        <div v-for="item in cards" :key="item.email" class="new_card">
          <div class="title">{{item.title}}</div>
          <div class="text">{{item.text}}</div>
          <!--<div class="info">{{item.email}}</div>-->
        </div>
        <div class="new_card" style="height: auto;">
          <div class="text" style="margin: 40px 0; font-weight: 600;">For business inquiries: <a style="text-decoration: underline;" href="mailto:bd@gv20tx.com">bd@gv20tx.com</a></div>
          <div class="text" style="margin: 40px 0; font-weight: 600;">For job opportunities: <a style="text-decoration: underline;" href="mailto:career@gv20tx.com">career@gv20tx.com</a></div>
        </div>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from '@/components/Footers'
export default {
  data () {
    return {
      cards: [
        {
          title: 'USA',
          text: '237 Putnam Ave, Cambridge, MA 02139, USA ',
          email: 'career@gv20tx.com'
        },
        {
          title: 'CHINA',
          text: '3rd floor, Building #4, 3728 Jinke Road Zhangjiang Hi-Tech Park Pudong New Area, Shanghai, China, 201203 ',
          email: 'xbhhr@bioxbh.com'
        },
      ]
    }
  },
  components: {
    Footers
  }
}
</script>
<style lang="scss" scoped>
$padding-left: 50px;
.contact {
  .banner {
    height: 558px;
    background: url(../assets/contact/headline_bg.jpg) no-repeat center
      center/cover;
    padding: 100px 150px 100px 30px;
    color: #fff;
    .title {
      margin-bottom: 40px;
    }
  }
  .map{
    margin-top: 80px;
  }
  .cards {
    margin-bottom: 160px;
    .new_card {
      height: 600px;
      padding: 104px 160px 102px 40px;
      .text{
        margin: 40px 0;
      }
      .info{
        font-size: 40px;
        font-weight: bold;
      }
    }
    .new_card:nth-of-type(1) {
      background: url(../assets/contact/USA-BG.jpg) no-repeat center
        center/cover;
    }
    .new_card:nth-of-type(2) {
      background: url(../assets/contact/China-BG.jpg) no-repeat center
        center/cover;
    }
  }
  .title {
    font-weight: bold;
    font-size: 50px;
  }
  .text {
    font-size: 40px;
  }
  .info {
    font-size: 30px;
  }
  .small {
    font-size: 32px;
    font-family: Helvetica-Oblique, Helvetica;
    font-weight: normal;
    color: #3852a3;
    line-height: 44px;
  }
}
</style>
